import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import {
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  Route,
} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
//import '@fontsource/poppins';

import App from './App';
import Home from './Pages/Home/Home';
import Gallary from './Pages/Gallary/Gallary';
// import Page404 from './Screens/Page404';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<App />}>
      <Route index element={<Home />} />  {/* Default route */}
      <Route path="gallary" element={<Gallary />} />  {/* Gallary route */}
      {/* //<Route path="*" element={<Page404 />} />  404 route */}
    </Route>
  )
);

createRoot(document.getElementById('root')).render(
  <StrictMode>
    <RouterProvider router={router} />
  </StrictMode>
);
